import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Lou = () => {
  return (
    <Layout>
      <Head title="Lou Patrou" />
      <h3>Lou Patrou</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        New York
      </h4>
      <p>
        <OutboundLink href="http://www.patrou.com/">Website</OutboundLink>
      </p>
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15389903/ello-optimized-c3d3dc92.jpg"
        alt="Lou Patrou Art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/15389833/ello-optimized-32029c5f.jpg"
        alt="Lou Patrou Art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/15389957/ello-optimized-cc8ab1b8.jpg"
        alt="Lou Patrou Art"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15386913/ello-optimized-cfe423a1.jpg"
        alt="Lou Patrou Art"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15386959/ello-optimized-bef8a156.jpg"
        alt="Lou Patrou Art"
      />
      {/* <img
        src="https://assets0.ello.co/uploads/asset/attachment/15382468/ello-optimized-837cbde0.jpg"
        alt="Lou Patrou Art"
      /> */}
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15382056/ello-optimized-45173115.jpg"
        alt="Lou Patrou Art"
      />
    </Layout>
  );
};

export default Lou;
